import {useEffect, useState} from "react";

function PageLoader({setLoaded}) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let perfData = window.performance.timing;
        let EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart);
        let time = parseInt((EstimatedTime/1000)%60)*100;

        let start = 0;
        let end = 100;
        let range = end - start,
            current = start,
            increment = end > start? 1 : -1,
            stepTime = Math.abs(Math.floor(time / range));

        let timer = setInterval(function() {
            current += increment;
            setProgress(current);
            if (current == end) {
                clearInterval(timer);
                setLoaded(true);
            }
        }, stepTime);
    }, []);

    return(
        <div className={'page__loader d-flex align-items-center justify-content-center' + (progress >= 100 ? ' loaded' : '')}>
            <div className="page__loader-bl">
                <div className="page__loader-icon">
                    <svg width="394" height="394" viewBox="0 0 394 394" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M392.988 196.763C392.988 305.018 305.227 392.777 196.968 392.777C88.7082 392.777 0.946777 305.018 0.946777 196.763C0.946777 88.5082 88.7082 0.75 196.968 0.75C305.227 0.75 392.988 88.5082 392.988 196.763Z" fill="#70F9BB" stroke="white" strokeWidth="1.5"/>
                        <path d="M197 294.127C54.0511 291.246 0 193.241 0 193.241C0 193.241 57.192 95.155 197 92.3548C327.528 89.7405 394 194.424 394 194.424C394 194.424 328.755 296.784 197 294.133V294.127Z" fill="white"/>
                        <g className="eye__circle-anim" style={{transform: 'translateX(' + progress / 2 + '%)'}}>
                            <path d="M95.6533 254.649C130.808 254.649 159.307 227.193 159.307 193.325C159.307 159.456 130.808 132 95.6533 132C60.4985 132 32 159.456 32 193.325C32 227.193 60.4985 254.649 95.6533 254.649Z" fill="black"/>
                        </g>
                    </svg>
                </div>
                <div className="page__loader-progress">
                    <span style={{width: progress + '%'}}></span>
                </div>
            </div>
        </div>
    )
}

export default PageLoader;
