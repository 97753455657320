import { useLocation  } from 'react-router-dom'
import {useEffect} from "react";

function PageRedirect({redirect, setRedirect}) {
    const location = useLocation();

    useEffect(() => {
        setRedirect(false);
    }, [location.pathname]);

    return(
        <div className={'page__redirect-box' + (redirect ? ' active' : '')}/>
    )
}

export default PageRedirect;
