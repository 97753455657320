import { Link, useLocation, useNavigate, useParams, useMatch  } from 'react-router-dom'
import {useEffect, useState, useLayoutEffect} from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
}

let scStArr = [];

function Header({loaded, redirect, setRedirect, logoTextColor, logoType, logoCircleColor}) {
    const location = useLocation();
    const navigate = useNavigate();
    const [inner, setInner] = useState(false);
    const [nav, setNav] = useState(false);
    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        setNav(false);
    }, [location.pathname]);

    const goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({behavior: 'smooth'});
        }
    }

    useEffect(()=>{
        if(loaded){
            gsap.timeline().from('#eye-toggle', {
                x: '-2.7rem',
                ease: "linear",
                yoyo: true,
                repeat: 1000,
                repeatDelay: 0.5
            });
        }
    }, [loaded]);

    return(
        <div id="header" className={'header' + (inner ? ' header--inner' : '') + (nav ? ' header--nav' : '')}>
           <div className="container d-flex align-items-center justify-content-between">
               <div onClick={()=>{
                   if(location.pathname !== '/') {
                       setRedirect(true);
                       setTimeout(()=>{
                           navigate('/');
                       }, 750);
                   }
               }} className="logo">
                   {logoType == 'media' && (
                       <svg width="201" height="72" viewBox="0 0 201 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M35.5 71.7695C55.1228 71.7695 71 55.8924 71 36.2695C71 16.6467 55.1228 0.769531 35.5 0.769531C15.8772 0.769531 0 16.6467 0 36.2695C0 55.8924 15.8772 71.7695 35.5 71.7695Z" fill={logoCircleColor}/>
                           <path d="M35.5 53.8266C9.81142 53.3111 0 35.6255 0 35.6255C0 35.6255 10.327 17.94 35.5 17.4244C58.9935 16.9088 71 35.8833 71 35.8833C71 35.8833 59.2513 54.3422 35.5 53.8266Z" fill="white"/>
                           <g id="eye-toggle">
                               <path d="M43.8894 46.7241C50.2129 46.7241 55.3803 41.8184 55.3803 35.6238C55.3803 29.4291 50.2168 24.5234 43.8894 24.5234C37.5619 24.5234 32.3984 29.4291 32.3984 35.6238C32.3984 41.8184 37.5619 46.7241 43.8894 46.7241Z" fill="black"/>
                           </g>
                           <g className="logo__txt">
                               <path d="M88.8677 35.9784V9.9255H93.8305V12.5446H93.9682C93.9682 12.5446 95.6238 9.375 99.8944 9.375C105.408 9.375 108.302 14.2002 108.302 19.576C108.302 24.9517 105.545 29.7769 99.8944 29.7769C95.7573 29.7769 94.1058 27.1579 94.1058 27.1579H93.9682V35.8408H88.8677V35.9784ZM93.9682 19.576C93.9682 23.0208 95.4862 25.9151 98.6558 25.9151C101.825 25.9151 103.343 23.1584 103.343 19.576C103.343 15.9935 101.825 13.2369 98.6558 13.2369C95.3486 13.3745 93.9682 16.1312 93.9682 19.576Z" fill={logoTextColor}/>
                               <path d="M132.974 19.576C132.974 23.0208 134.355 25.9151 137.662 25.9151C140.969 25.9151 142.35 23.1584 142.35 19.576C142.35 15.9935 140.969 13.2369 137.662 13.2369C134.355 13.3745 132.974 16.1312 132.974 19.576ZM127.874 19.576C127.874 13.925 131.319 9.375 137.662 9.375C144.005 9.375 147.45 13.925 147.45 19.576C147.45 25.2269 144.005 29.7769 137.662 29.7769C131.323 29.9146 127.874 25.2269 127.874 19.576Z" fill={logoTextColor}/>
                               <path d="M158.06 9.925V29.2218H152.822V16.5394H148.685V13.9203C154.06 13.9203 155.024 9.7832 155.024 9.7832H158.056V9.92083L158.06 9.925Z" fill={logoTextColor}/>
                               <path d="M165.229 19.576C165.229 23.0208 166.609 25.9151 169.916 25.9151C173.224 25.9151 174.604 23.1584 174.604 19.576C174.604 15.9935 173.224 13.2369 169.916 13.2369C166.472 13.3745 165.229 16.1312 165.229 19.576ZM159.991 19.576C159.991 13.925 163.436 9.375 169.779 9.375C176.122 9.375 179.567 13.925 179.567 19.576C179.567 25.2269 176.122 29.7769 169.779 29.7769C163.44 29.9146 159.991 25.2269 159.991 19.576Z" fill={logoTextColor}/>
                               <path d="M186.32 19.576C186.32 23.0208 187.7 25.9151 191.007 25.9151C194.315 25.9151 195.695 23.1584 195.695 19.576C195.695 15.9935 194.315 13.2369 191.007 13.2369C187.7 13.3745 186.32 16.1312 186.32 19.576ZM181.219 19.576C181.219 13.925 184.664 9.375 191.007 9.375C197.351 9.375 200.795 13.925 200.795 19.576C200.795 25.2269 197.351 29.7769 191.007 29.7769C184.664 29.7769 181.219 25.2269 181.219 19.576Z" fill={logoTextColor}/>
                               <path d="M100.17 63.8199V53.0684C100.17 49.7613 99.4814 48.2432 97.4129 48.2432C95.2067 48.2432 93.9681 49.8989 93.9681 53.7566V63.8199H88.73V44.5232H93.6928V47.6927H93.8305C93.8305 47.6927 95.0691 43.9727 99.0686 43.9727C102.238 43.9727 103.894 45.4907 104.582 47.6927H104.72C104.72 47.6927 106.375 43.9727 110.233 43.9727C115.058 43.9727 116.434 47.5551 116.434 51.9674V63.8199H111.196V53.0684C111.196 49.7613 110.508 48.2432 108.44 48.2432C106.233 48.2432 104.995 49.8989 104.995 53.7566V63.8199H100.17Z" fill={logoTextColor}/>
                               <path d="M118.503 54.031C118.503 48.3801 121.811 43.8301 128.154 43.8301C134.497 43.8301 137.387 48.3801 137.387 54.031V55.132H123.742C124.017 58.164 125.26 60.3702 128.292 60.3702C131.323 60.3702 132.012 57.3382 132.012 57.3382H137.25C137.25 57.3382 136.149 64.232 128.154 64.232C121.54 64.3696 118.503 59.682 118.503 54.031ZM128.154 47.8296C125.535 47.8296 124.296 49.4852 123.879 51.9667H132.149C131.736 49.4852 130.631 47.8296 128.154 47.8296Z" fill={logoTextColor}/>
                               <path d="M158.339 37.7676V63.8205H153.376V61.2014H153.239C153.239 61.2014 151.583 64.371 147.312 64.371C141.799 64.371 138.905 59.5458 138.905 54.17C138.905 48.7943 141.661 43.9691 147.312 43.9691C151.45 43.9691 153.101 46.5881 153.101 46.5881H153.239V37.9052H158.339V37.7676ZM153.376 54.0324C153.376 50.5876 151.858 47.6933 148.689 47.6933C145.519 47.6933 144.001 50.45 144.001 54.0324C144.001 57.6148 145.519 60.3715 148.689 60.3715C151.858 60.3715 153.376 57.6148 153.376 54.0324Z" fill={logoTextColor}/>
                               <path d="M166.193 44.3848V63.6815H160.954V44.3848H166.193ZM160.679 40.1101C160.679 38.4544 162.06 37.0781 163.711 37.0781C165.363 37.0781 166.743 38.4585 166.743 40.1101C166.743 41.7616 165.363 43.142 163.711 43.142C161.918 43.142 160.679 41.7616 160.679 40.1101Z" fill={logoTextColor}/>
                               <path d="M174.187 50.3127H168.949C169.225 45.3498 173.224 43.8359 177.632 43.8359C182.32 43.8359 186.04 45.4916 186.04 50.5879V63.8208H180.802V60.1008H180.664C180.664 60.1008 179.426 64.3755 174.463 64.3755C170.88 64.3755 168.261 62.7198 168.261 59.1374C168.261 55.5549 171.293 53.8993 174.463 53.0735C177.632 52.2478 180.802 51.9725 180.802 49.904C180.802 48.3859 179.701 47.4225 177.632 47.4225C175.564 47.4225 174.325 48.2483 174.187 50.3168V50.3127ZM180.939 54.0327C180.114 54.8585 178.32 55.2714 176.802 55.5508C175.147 55.9637 173.77 56.7894 173.77 58.5827C173.77 60.1008 174.871 61.0641 176.527 61.0641C179.972 60.9265 180.939 56.927 180.939 54.0327Z" fill={logoTextColor}/>
                               <path d="M119.395 9.92383H110.095V29.3582H115.508V13.3937H118.007C120.229 13.3937 121.059 13.3937 121.059 15.8918V17.6976H126.335V15.4747C126.335 11.1708 124.809 9.92383 119.395 9.92383Z" fill={logoTextColor}/>
                           </g>
                       </svg>
                   )}
                   {logoType != 'media' && (
                       <svg width="215" height="56" viewBox="0 0 215 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M27.5 55.22C42.7008 55.22 55 42.9208 55 27.72C55 12.5192 42.7008 0.219971 27.5 0.219971C12.2992 0.219971 0 12.5192 0 27.72C0 42.9208 12.2992 55.22 27.5 55.22Z" fill={logoCircleColor}/>
                           <path d="M27.5 41.3204C7.6004 40.9211 0 27.221 0 27.221C0 27.221 7.99978 13.5209 27.5 13.1215C45.6992 12.7221 55 27.4207 55 27.4207C55 27.4207 45.8989 41.7198 27.5 41.3204Z" fill="white"/>
                           <g id="eye-toggle">
                               <path d="M33.999 35.8195C38.8975 35.8195 42.9004 32.0193 42.9004 27.2207C42.9004 22.422 38.9005 18.6218 33.999 18.6218C29.0974 18.6218 25.0975 22.422 25.0975 27.2207C25.0975 32.0193 29.0974 35.8195 33.999 35.8195Z" fill="black"/>
                           </g>
                           <g className="logo__txt">
                               <path d="M68.7891 24.5084V4.47722H72.6048V6.49092H72.7107C72.7107 6.49092 73.9836 4.05396 77.2671 4.05396C81.5062 4.05396 83.7315 7.76391 83.7315 11.8971C83.7315 16.0304 81.612 19.7403 77.2671 19.7403C74.0863 19.7403 72.8165 17.7266 72.8165 17.7266H72.7107V24.4026L68.7891 24.5084ZM72.7107 11.8971C72.7107 14.5457 73.8778 16.7711 76.3148 16.7711C78.7518 16.7711 79.9189 14.6515 79.9189 11.8971C79.9189 9.14272 78.7518 7.0232 76.3148 7.0232C73.772 7.12902 72.7107 9.24854 72.7107 11.8971Z" fill={logoTextColor}/>
                               <path d="M92.2099 4.47729H85.1106V19.3139H89.2438V7.12589H91.1517C92.848 7.12589 93.4828 7.12589 93.4828 9.03378V10.4126H97.5102V8.71633C97.5102 5.42964 96.3431 4.47729 92.2099 4.47729Z" fill={logoTextColor}/>
                               <path d="M92.2099 31.0786H85.1106V45.9152H89.2438V33.7272H91.1517C92.848 33.7272 93.4828 33.7272 93.4828 35.6351V37.0139H97.5102V35.3176C97.5102 32.031 96.3431 31.0786 92.2099 31.0786Z" fill={logoTextColor}/>
                               <path d="M102.701 11.8971C102.701 14.5457 103.763 16.7711 106.305 16.7711C108.848 16.7711 109.91 14.6515 109.91 11.8971C109.91 9.14272 108.848 7.0232 106.305 7.0232C103.763 7.12902 102.701 9.24854 102.701 11.8971ZM98.7797 11.8971C98.7797 7.55228 101.428 4.05396 106.305 4.05396C111.183 4.05396 113.831 7.55228 113.831 11.8971C113.831 16.242 111.183 19.7403 106.305 19.7403C101.431 19.8461 98.7797 16.242 98.7797 11.8971Z" fill={logoTextColor}/>
                               <path d="M121.989 4.47719V19.3138H117.961V9.56275H114.78V7.54904C118.914 7.54904 119.654 4.36816 119.654 4.36816L121.985 4.47398L121.989 4.47719Z" fill={logoTextColor}/>
                               <path d="M127.501 11.8971C127.501 14.5457 128.562 16.7711 131.105 16.7711C133.648 16.7711 134.709 14.6515 134.709 11.8971C134.709 9.14272 133.648 7.0232 131.105 7.0232C128.456 7.12902 127.501 9.24854 127.501 11.8971ZM123.473 11.8971C123.473 7.55228 126.122 4.05396 130.999 4.05396C135.876 4.05396 138.525 7.55228 138.525 11.8971C138.525 16.242 135.876 19.7403 130.999 19.7403C126.125 19.8461 123.473 16.242 123.473 11.8971Z" fill={logoTextColor}/>
                               <path d="M143.716 11.8971C143.716 14.5457 144.777 16.7711 147.32 16.7711C149.863 16.7711 150.924 14.6515 150.924 11.8971C150.924 9.14272 149.863 7.0232 147.32 7.0232C144.777 7.12902 143.716 9.24854 143.716 11.8971ZM139.794 11.8971C139.794 7.55228 142.443 4.05396 147.32 4.05396C152.197 4.05396 154.846 7.55228 154.846 11.8971C154.846 16.242 152.197 19.7403 147.32 19.7403C142.443 19.7403 139.794 16.242 139.794 11.8971Z" fill={logoTextColor}/>
                               <path d="M68.7896 51.0009V30.9696H72.6053V32.9834H72.7111C72.7111 32.9834 73.9841 30.5464 77.2676 30.5464C81.5067 30.5464 83.732 34.2563 83.732 38.3896C83.732 42.5228 81.6125 46.2327 77.2676 46.2327C74.0867 46.2327 72.817 44.219 72.817 44.219H72.7111V50.895L68.7896 51.0009ZM72.7111 38.3896C72.7111 41.0382 73.8783 43.2635 76.3153 43.2635C78.7522 43.2635 79.9194 41.144 79.9194 38.3896C79.9194 35.6352 78.7522 33.5156 76.3153 33.5156C73.7725 33.6214 72.7111 35.741 72.7111 38.3896Z" fill={logoTextColor}/>
                               <path d="M102.701 38.3896C102.701 41.0382 103.763 43.2635 106.305 43.2635C108.848 43.2635 109.91 41.144 109.91 38.3896C109.91 35.6352 108.848 33.5156 106.305 33.5156C103.763 33.6214 102.701 35.741 102.701 38.3896ZM98.7797 38.3896C98.7797 34.0447 101.428 30.5464 106.305 30.5464C111.183 30.5464 113.831 34.0447 113.831 38.3896C113.831 42.7344 111.183 46.2327 106.305 46.2327C101.431 46.3386 98.7797 42.7344 98.7797 38.3896Z" fill={logoTextColor}/>
                               <path d="M130.044 25.8843V45.9155H126.228V43.9018H126.122C126.122 43.9018 124.849 46.3387 121.565 46.3387C117.326 46.3387 115.101 42.6288 115.101 38.4956C115.101 34.3623 117.221 30.6524 121.565 30.6524C124.746 30.6524 126.016 32.6661 126.016 32.6661H126.122V25.9901L130.044 25.8843ZM126.228 38.3898C126.228 35.7412 125.061 33.5158 122.624 33.5158C120.187 33.5158 119.019 35.6353 119.019 38.3898C119.019 41.1442 120.187 43.2637 122.624 43.2637C125.061 43.2637 126.228 41.1442 126.228 38.3898Z" fill={logoTextColor}/>
                               <path d="M145.942 30.9731V45.8098H142.126V43.3728H142.02C142.02 43.3728 140.747 46.233 137.464 46.233C133.331 46.233 132.163 43.4786 132.163 40.0861V30.9731H136.191V39.2396C136.191 41.7824 136.931 42.9495 138.734 42.9495C140.853 42.9495 142.02 41.6765 142.02 38.7105V30.9731H145.942Z" fill={logoTextColor}/>
                               <path d="M147.744 38.3896C147.744 34.0447 150.286 30.5464 155.163 30.5464C161.734 30.5464 162.157 36.3759 162.157 36.3759H158.235C158.235 36.3759 158.129 33.5156 155.269 33.5156C152.726 33.5156 151.877 35.6352 151.877 38.3896C151.877 41.144 152.723 43.2635 155.269 43.2635C158.129 43.2635 158.235 40.4033 158.235 40.4033H162.263C162.263 40.4033 161.839 46.2327 155.269 46.2327C150.395 46.3386 147.744 42.7344 147.744 38.3896Z" fill={logoTextColor}/>
                               <path d="M162.897 30.9732H176.355V34.1541H171.481V41.1475C171.481 42.4205 171.904 42.8438 173.177 42.8438C174.024 42.8438 174.45 42.7379 174.45 42.7379V45.704C174.45 45.704 173.71 46.0214 172.013 46.0214C169.259 46.0214 167.457 45.2807 167.457 41.8882V34.1508H162.9V30.97L162.897 30.9732Z" fill={logoTextColor}/>
                               <path d="M182.079 30.9731V45.8097H178.052V30.9731H182.079ZM177.734 27.6864C177.734 26.4134 178.795 25.3552 180.065 25.3552C181.335 25.3552 182.396 26.4166 182.396 27.6864C182.396 28.9562 181.335 30.0175 180.065 30.0175C178.795 30.0175 177.734 28.9562 177.734 27.6864Z" fill={logoTextColor}/>
                               <path d="M187.909 38.3896C187.909 41.0382 188.97 43.2635 191.513 43.2635C194.056 43.2635 195.117 41.144 195.117 38.3896C195.117 35.6352 194.056 33.5156 191.513 33.5156C188.97 33.6214 187.909 35.741 187.909 38.3896ZM183.881 38.3896C183.881 34.0447 186.53 30.5464 191.407 30.5464C196.284 30.5464 198.933 34.0447 198.933 38.3896C198.933 42.7344 196.284 46.2327 191.407 46.2327C186.53 46.2327 183.881 42.7344 183.881 38.3896Z" fill={logoTextColor}/>
                               <path d="M200.943 45.9152V31.0785H204.759V33.5155H204.865C204.865 33.5155 206.138 30.6553 209.421 30.6553C213.555 30.6553 214.722 33.4097 214.722 36.8022V45.9152H210.694V37.6487C210.694 35.1059 209.954 33.9388 208.152 33.9388C206.032 33.9388 204.865 35.2118 204.865 38.1778V45.9152H200.943Z" fill={logoTextColor}/>
                           </g>
                       </svg>
                   )}
               </div>
               <div className="nav__wrap">
                   <div className="nav__box">
                       <div className="nav__menu">
                           <ul>
                               <li>
                                   <a onClick={()=>{
                                       if(location.pathname !== '/') {
                                           setRedirect(true);
                                           setTimeout(()=>{
                                               navigate('/#about');
                                           }, 750);
                                       }else{
                                           goToAnchor('about');
                                           setNav(false);
                                       }
                                   }}>Кто мы</a>
                               </li>
                               <li>
                                   <a onClick={()=>{
                                       if(location.pathname !== '/') {
                                           setRedirect(true);
                                           setTimeout(()=>{
                                               navigate('/#advants');
                                           }, 750);
                                       }else{
                                           goToAnchor('advants');
                                           setNav(false);
                                       }
                                   }}>Что мы можем</a>
                               </li>
                               <li>
                                   <a onClick={()=>{
                                       if(location.pathname !== '/') {
                                           setRedirect(true);
                                           setTimeout(()=>{
                                               navigate('/#projects');
                                           }, 750);
                                       }else{
                                           goToAnchor('projects');
                                           setNav(false);
                                       }
                                   }}>Наши проекты</a>
                               </li>
                               <li>
                                   <a onClick={()=>{
                                       if(location.pathname !== '/') {
                                           setRedirect(true);
                                           setTimeout(()=>{
                                               navigate('/#clients');
                                           }, 750);
                                       }else{
                                           goToAnchor('clients');
                                           setNav(false);
                                       }
                                   }}>Наши клиенты</a>
                               </li>
                               <li>
                                   <a onClick={()=>{
                                       if(location.pathname !== '/contacts') {
                                           setNav(false);
                                           setRedirect(true);
                                           setTimeout(()=>{
                                               navigate('/contacts');
                                           }, 750);
                                       }else{
                                           setNav(false);
                                       }
                                   }}>Контакты</a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div onClick={()=>{
                       setNav(!nav);
                   }} className="nav__bars d-flex align-items-center justify-content-center">
                       {!nav && (
                           <i className="icon__bars fadeIn animated">
                               <img src={require("../assets/img/icon-bars.svg").default} className="desk-only"/>
                               <img src={require("../assets/img/icon-bars-mb.svg").default} className="mb-only"/>
                           </i>
                       )}
                       {nav && (
                           <i className="icon__close fadeIn animated">
                               <img src={require("../assets/img/icon-close.svg").default}/>
                           </i>
                       )}
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Header;
