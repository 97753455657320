import CustomScroll from 'react-custom-scroll';
import {useModal} from "../context";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { useKeenSlider } from "keen-slider/react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "keen-slider/keen-slider.min.css"
import React, {useState, useRef, useEffect} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

function Contacts({loaded, redirect, setRedirect, setLogoCircleColor, setLogoTextColor}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const schemaObj = {
        name: yup.string().required('Некорректное имя'),
        email: yup.string().email('Некорректный email').required('Некорректный email'),
        phone: yup.string().required('Некорректный телефон'),
        project: yup.string().required('Обязательно к заполнению'),
        message: yup.string().required('Обязательно к заполнению'),
        budjet: yup.string().nullable(),
        endDate: yup.string().nullable(),
    };
    const schema = yup.object(schemaObj).required();
    const { register, getValues, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchBudjet = watch("budjet");
    const watchEndDate = watch("endDate");

    useEffect(()=>{
        setValue('budjet', '0');
        setValue('endDate', '1');
        setLogoCircleColor('#70F9BB');
        setLogoTextColor('#000000');
    }, []);

    const onSubmit = (data) => {

    }

    useEffect(()=>{
        if(loaded && !redirect){
            let tl1 = gsap.timeline().from('.anim__it--1 .anim__it-bg', {
                width: '16.4rem',
                ease: "linear",
                duration: 0.7,
                onComplete() {
                    let trg = this.targets();
                    setTimeout(function (){
                        gsap.set(trg, { clearProps: "all" });
                    }, 300);
                }
            });

            let tl2 = gsap.timeline().from('.anim__it--1 .sc__title', {
                opacity: 0,
                ease: "linear",
                duration: 0.7,
                onComplete() {
                    let trg = this.targets();
                    setTimeout(function (){
                        gsap.set(trg, { clearProps: "all" });
                    }, 300);
                }
            });
        }
    }, [loaded, redirect]);

    return(
        <div className="sc__inner sc__contacts-inner">
            <div className="container">
                <div className="sc__header">
                    <div className="sc__title">
                        Расскажите <br/>
                        нам
                    </div>
                    <div className="anim__box">
                        <div className="anim__it anim__it--1 active">
                            <div className="anim__it-bg"/>
                            <div className="sc__title">
                                о проекте
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__before d-flex align-items-center justify-content-between">
                    <div onClick={()=>{
                        document.getElementById('form').scrollIntoView({behavior: 'smooth'});
                    }} className="form__before-arrow">
                        <svg width="25" height="54" viewBox="0 0 25 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.4414 42.1094C19.6102 45.8245 13.8132 51.4458 12.8568 52.3732C12.7793 52.4484 12.6594 52.4451 12.5818 52.3698L2.00073 42.1094" stroke="#050505" strokeWidth="2.5" strokeLinecap="round"/>
                            <path d="M13.9695 1.99999C13.9695 1.30964 13.4099 0.749997 12.7195 0.75C12.0292 0.750003 11.4695 1.30965 11.4695 2.00001L13.9695 1.99999ZM13.9697 53L13.9695 1.99999L11.4695 2.00001L11.4697 53L13.9697 53Z" fill="#050505"/>
                        </svg>
                    </div>
                    <div className="sc__subtitle">
                        Заполните форму проекта ниже, <br/>
                        и&nbsp;давайте воплотим ваши <br/>
                        желания в&nbsp;реальность!
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}  className={'form__contacts' + (isSubmitting ? ' form--loading' : '')}>
                    <div className="form__anchor" id="form"></div>
                    <div className="form__groups">
                        <div className="form__group">
                            <div className="form__group-title">
                                Ваша контактная информация
                            </div>
                            <div className="form__list">
                                <div className="row">
                                    <div className="col-6 form__it">
                                        <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                placeholder="Имя"
                                                {...register("name")}
                                            />
                                        </div>
                                        {errors.name && (
                                            <p className="error__message">
                                                {errors.name?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-6 form__it">
                                        <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                placeholder="E-mail"
                                                {...register("email")}
                                            />
                                        </div>
                                        {errors.email && (
                                            <p className="error__message">
                                                {errors.email?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-6 form__it">
                                        <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                            <InputMask
                                                autoComplete="nope"
                                                type="text"
                                                placeholder="Номер телефона"
                                                mask="+799999999999"
                                                alwaysShowMask={false}
                                                {...register("phone")}
                                            />
                                        </div>
                                        {errors.phone && (
                                            <p className="error__message">
                                                {errors.phone?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-6 form__it">
                                        <div className={'form__field' + (errors.project ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                placeholder="Название проекта"
                                                {...register("project")}
                                            />
                                        </div>
                                        {errors.project && (
                                            <p className="error__message">
                                                {errors.project?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__group">
                            <div className="form__group-title">
                                Бюджет
                            </div>
                            <div className="form__list">
                                <div className="radio__list d-flex flex-wrap">
                                    <div onClick={()=>{
                                        setValue('budjet', '0');
                                    }} className={'radio__it d-flex align-items-center' + (watchBudjet == 0 ? ' active' : '')}>
                                        Неизвестен
                                    </div>
                                    <div onClick={()=>{
                                        setValue('budjet', '1');
                                    }} className={'radio__it d-flex align-items-center' + (watchBudjet == 1 ? ' active' : '')}>
                                        Меньше 500.000 ₽
                                    </div>
                                    <div onClick={()=>{
                                        setValue('budjet', '2');
                                    }} className={'radio__it d-flex align-items-center' + (watchBudjet == 2 ? ' active' : '')}>
                                        500.000 - 1.000.000 ₽
                                    </div>
                                    <div onClick={()=>{
                                        setValue('budjet', '3');
                                    }} className={'radio__it d-flex align-items-center' + (watchBudjet == 3 ? ' active' : '')}>
                                        1.000.000 - 1.5000.00 ₽
                                    </div>
                                    <div onClick={()=>{
                                        setValue('budjet', '4');
                                    }} className={'radio__it d-flex align-items-center' + (watchBudjet == 4 ? ' active' : '')}>
                                        Больше - 1.5000.00 ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__group">
                            <div className="form__group-title">
                                О вашем проекте
                            </div>
                            <div className="form__list">
                                <div className="row">
                                    <div className="col-12 form__it">
                                        <div className={'form__field' + (errors.message ? ' has-error' : '')}>
                                        <textarea
                                            placeholder="Расскажите побольше о вашем проекте"
                                            {...register("message")}
                                        />
                                        </div>
                                        {errors.message && (
                                            <p className="error__message">
                                                {errors.message?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__group">
                            <div className="form__group-title">
                                Завершения проекта
                            </div>
                            <div className="form__list">
                                <div className="radio__list d-flex flex-wrap">
                                    <div onClick={()=>{
                                        setValue('endDate', '1');
                                    }} className={'radio__it d-flex align-items-center' + (watchEndDate == 1 ? ' active' : '')}>
                                        Нет четкой даты
                                    </div>
                                    <div onClick={()=>{
                                        setValue('endDate', '2');
                                    }} className={'radio__it d-flex align-items-center' + (watchEndDate == 2 ? ' active' : '')}>
                                        Ближайшие 2 месяца
                                    </div>
                                    <div onClick={()=>{
                                        setValue('endDate', '3');
                                    }} className={'radio__it d-flex align-items-center' + (watchEndDate == 3 ? ' active' : '')}>
                                        Менее 6 месяцев
                                    </div>
                                    <div onClick={()=>{
                                        setValue('endDate', '4');
                                    }} className={'radio__it d-flex align-items-center' + (watchEndDate == 4 ? ' active' : '')}>
                                        Менее 1 года
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="submit" className="btn__arrow btn__arrow--filled d-flex align-items-center justify-content-center">
                            <span>Отправить</span>
                            <i className="icon__right">
                                <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                                </svg>
                            </i>
                        </button>
                    </div>
                </form>
                <div className="d-flex justify-content-end">
                    <div onClick={()=>{
                        setRedirect(true);
                        setTimeout(()=>{
                            navigate('/');
                        }, 750);
                    }} className="btn__arrow d-flex align-items-center justify-content-center">
                        <span>На главную</span>
                        <i className="icon__right">
                            <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
            <div className="sc__contacts-email">
                <a href="mailto:projects@prosto-media.com">projects@prosto-media.com</a>
            </div>
        </div>
    )
}

export default Contacts;
