import { Routes, Route } from 'react-router-dom'
import React, {useEffect, useState} from 'react';
import {ModalProvider} from "./context";
import {NavigationProvider} from "./context";
import Home from './pages/Home'
import Contacts from './pages/Contacts'
import Projects from './pages/Projects'
import Header from "./components/Header";
import PageRedirect from "./components/PageRedirect";
import PageLoader from "./components/PageLoader";
import LocomotiveScroll from "locomotive-scroll";

let scroller = null;

function App() {
  const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [logoTextColor, setLogoTextColor] = useState('#000000');
  const [logoCircleColor, setLogoCircleColor] = useState('#70F9BB');
  const [logoType, setLogoType] = useState('media');

  useEffect(()=>{
      if(loaded && !redirect){
          if(scroller){
              scroller.destroy();
              scroller = null;
          }
          scroller = new LocomotiveScroll({
              lenisOptions: {
                  smoothTouch: true,
              },
          });
      }
  }, [loaded, redirect]);

  return (
      <ModalProvider>
          <div data-scroll-container="" id="wrapper" className="wrapper">
              <PageLoader setLoaded={setLoaded}/>
              <Header logoTextColor={logoTextColor} logoType={logoType} logoCircleColor={logoCircleColor} loaded={loaded} setRedirect={setRedirect}/>
              <Routes>
                  <Route path="/" element={<Home setLogoType={setLogoType} redirect={redirect} setLogoTextColor={setLogoTextColor} setLogoCircleColor={setLogoCircleColor} setRedirect={setRedirect} loaded={loaded}/>} />
                  <Route path="/contacts" element={<Contacts setLogoTextColor={setLogoTextColor} setLogoCircleColor={setLogoCircleColor} setRedirect={setRedirect} redirect={redirect} loaded={loaded}/>} />
                  <Route path="/projects" element={<Projects setLogoType={setLogoType} setLogoTextColor={setLogoTextColor} setLogoCircleColor={setLogoCircleColor} setRedirect={setRedirect} redirect={redirect} loaded={loaded}/>} />
              </Routes>
              <PageRedirect redirect={redirect} setRedirect={setRedirect}/>
          </div>
      </ModalProvider>
  );
}

export default App;
